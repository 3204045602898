import { withApplicationInsights } from 'next-applicationinsights';
import { appWithTranslation, useTranslation } from 'next-i18next';
import type { AppProps as NextAppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { Suspense, useEffect, useState } from 'react';
import { clarity } from 'react-microsoft-clarity';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DynamicMeta from '../components/DynamicMeta/DynamicMeta';
import { GlobalService } from '../helpers/services';
import ConfigurationService from '../helpers/services/configuration.service';
import { NextPageContext } from 'next';
import Script from 'next/script';
import { AppProvider } from '../provider/AppProvider';
import { CityProvider } from '../provider/CityProvider';
import { GlobalDataProvider } from '../provider/GlobalDataProvider';
import { SeoTagProvider } from '../provider/SeoTagProvider';
import { TranslationProvider } from '../provider/TranslationProvider';
import '../styles/globals.scss';
import { AppTheme, LogoUrl } from '../types/constants';
import { ConfigurationKey } from '../types/enums';
import {
  AllConfigurationResponse,
  ConfigurationResponse,
  NewHeaderMenu,
  SocialMediaResponse,
} from '../types/models';
import StoreProvider from './StoreProvider';
import dynamic from 'next/dynamic';

const Header = dynamic(() => import('../components/common/Header'), {
  ssr: true, // Disable SSR for this component
});

const Footer = dynamic(() => import('../components/common/Footer'), {
  ssr: false, // Disable SSR for this component
});

const ContinueListingToast = dynamic(
  () => import('../components/componentsV2/selfListing/ContinueListingToast'),
  {
    ssr: false, // Disable SSR for this component
  }
);

interface AppProps extends NextAppProps {
  metaData: {
    title: string;
    description: string;
    keywords: string[];
  };
  canonicalUrl: string;
  hrefLangUrl: string;
  locale: string;
}
function MyApp({
  Component,
  pageProps: { session, ...pageProps },
  metaData,
  canonicalUrl,
  hrefLangUrl,
  locale,
}: AppProps) {
  const { i18n } = useTranslation();
  const router = useRouter();
  const moeDataCenter = process.env.NEXT_PUBLIC_MOENGAGE_CLUSTER!;
  const moeAppID = process.env.NEXT_PUBLIC_MOENGAGE_APP_ID!;
  const [careerEmail, setCareerEmail] = useState<ConfigurationResponse>();
  const [socialMedia, setSocialMediaData] = useState<SocialMediaResponse>();
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [headerMenu, setHeaderMenu] = useState<Array<NewHeaderMenu>>([]);

  useEffect(() => {
    const id = process?.env?.NEXT_PUBLIC_CLARITY_ID!;
    if (id) {
      clarity.init(id);
    }
  }, []);

  useEffect(() => {
    const initialLoad = async () => {
      /* Load application header menu */
      const [
        configRes,
        socialMediaData,
        phoneNumberConfigurationData,
        headerData,
      ] = await Promise.all([
        ConfigurationService.fetchConfigurationValue(
          ConfigurationKey.CareersEmailId,
          router.locale
        ),
        GlobalService.fetchSocialMedia(),
        ConfigurationService.fetchConfigurationValue(
          ConfigurationKey.CustomerPhoneNumber
        ),
        GlobalService.getNewHeaderMenu(router.locale),
      ]);
      setCareerEmail(configRes);
      setSocialMediaData(socialMediaData);
      phoneNumberConfigurationData &&
        setPhoneNumber(phoneNumberConfigurationData.ConfigurationValue);
      setHeaderMenu(headerData);
    };

    if (router.locale) initialLoad();
  }, [router.locale]);

  const loadTheme = () => {
    switch (pageProps.applyTheme) {
      case AppTheme.V1:
        return 'theme-v1';
      default:
        return 'theme-default';
    }
  };
  function storePathValues() {
    const storage = globalThis?.sessionStorage;
    if (!storage) return;
    // Set the previous path as the value of the current path.
    const prevPath: any = storage.getItem('currentPath');
    storage.setItem('prevPath', prevPath ? prevPath : '/');
    // Set the current path value by looking at the browser's location object.
    storage.setItem('currentPath', globalThis?.location?.pathname);
  }

  return (
    <GlobalDataProvider>
      <TranslationProvider>
        <StoreProvider>
          {/* <MoEngageProvider> */}
          <CityProvider>
            <SeoTagProvider>
              <AppProvider>
                <Head>
                  <link
                    rel="icon"
                    href={`${process.env.NEXT_PUBLIC_APP_CDN_URL}/b2cpages/favicon.ico`}
                  />
                  <link
                    rel="preconnect"
                    href={'https://po-cdn-nonprod.azureedge.net'}
                  />
                </Head>
                {process.env.NEXT_PUBLIC_ENABLE_ADSENSE === 'true' ? (
                  <Script
                    async
                    src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-${process.env.NEXT_PUBLIC_GOOGLE_PUBLISHER_ID}`}
                    crossOrigin="anonymous"
                    strategy="lazyOnload"
                  />
                ) : (
                  <></>
                )}
                <Script
                  id="gtm-base"
                  strategy="lazyOnload"
                  dangerouslySetInnerHTML={{
                    __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GTM_IDENTIFIER}');`,
                  }}
                  async
                />
                <Script
                  id="google-tag-manager_1"
                  strategy="lazyOnload"
                  async
                  src="https://www.googletagmanager.com/gtag/js?id=AW-16637799978"
                />
                <Script id="google-tag-manager_2" strategy="lazyOnload" async>
                  {`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'AW-16637799978');gtag('event', 'conversion', {'send_to': 'AW-16637799978/xZaRCOShgcEZEKrcwv09'});`}
                </Script>
                <script
                  dangerouslySetInnerHTML={{
                    __html: `!function(e,n,i,t,a,r,o,d){if(!"${moeDataCenter}"||"{DC}"==="${moeDataCenter}")return console.error("Data center has not been passed correctly. Please follow the SDK installation instruction carefully.");var s=e[a]=e[a]||[];if(s.invoked=0,s.initialised>0||s.invoked>0)return console.error("MoEngage Web SDK initialised multiple times. Please integrate the Web SDK only once!"),!1;e.moengage_object=a;var l={},g=function n(i){return function(){for(var n=arguments.length,t=Array(n),a=0;a<n;a++)t[a]=arguments[a];(e.moengage_q=e.moengage_q||[]).push({f:i,a:t})}},u=["track_event","add_user_attribute","add_first_name","add_last_name","add_email","add_mobile","add_user_name","add_gender","add_birthday","destroy_session","add_unique_user_id","update_unique_user_id","moe_events","call_web_push","track","location_type_attribute"],m={onsite:["getData","registerCallback"]};for(var c in u)l[u[c]]=g(u[c]);for(var v in m)for(var f in m[v])null==l[v]&&(l[v]={}),l[v][m[v][f]]=g(v+"."+m[v][f]);r=n.createElement(i),o=n.getElementsByTagName("head")[0],r.async=1,r.src=t,o.appendChild(r),e.moe=e.moe||function(){return(s.invoked=s.invoked+1,s.invoked>1)?(console.error("MoEngage Web SDK initialised multiple times. Please integrate the Web SDK only once!"),!1):(d=arguments.length<=0?void 0:arguments[0],l)},r.addEventListener("load",function(){if(d)return e[a]=e.moe(d),e[a].initialised=e[a].initialised+1||1,!0}),r.addEventListener("error",function(){return console.error("Moengage Web SDK loading failed."),!1})}(window,document,"script","https://cdn.moengage.com/release/${moeDataCenter}/moe_webSdk.min.latest.js","Moengage");`,
                  }}
                ></script>
                <script
                  dangerouslySetInnerHTML={{
                    __html: `
          Moengage = moe({
            app_id:"${moeAppID}",
            debug_logs: 1
          });`,
                  }}
                ></script>
                {/* {loading ? (
            <GogoLoader />
          ) : */}
                <Suspense fallback={null}>
                  <DynamicMeta
                    metaData={metaData}
                    hrefLangUrl={hrefLangUrl}
                    canonicalUrl={canonicalUrl}
                    locale={locale}
                    imageUrl={pageProps?.imageUrl || LogoUrl}
                  />
                </Suspense>
                {router.pathname === '/petromin-auth' ||
                pageProps.hideLayout ? (
                  <main className={loadTheme()}>
                    <Component {...pageProps} />
                    <div id="app-message-box" />
                  </main>
                ) : (
                  <div className={loadTheme()}>
                    {!pageProps.hideHeader && (
                      <Suspense fallback={null}>
                        <Header
                          careerEmail={careerEmail!}
                          socialMedia={socialMedia!}
                          phoneNumber={phoneNumber}
                          headerMenu={headerMenu}
                        />
                      </Suspense>
                    )}
                    <main className="main">
                      <Component {...pageProps} />
                    </main>
                    <Suspense fallback={null}>
                      <ContinueListingToast />
                    </Suspense>
                    <Suspense fallback={null}>
                      <Footer
                        careerEmail={careerEmail!}
                        socialMedia={socialMedia!}
                        phoneNumber={phoneNumber}
                        headerMenu={headerMenu}
                      />
                    </Suspense>
                    <div id="app-message-box" />
                    <noscript>
                      <iframe
                        src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM_IDENTIFIER}`}
                        height="0"
                        width="0"
                        style={{ display: 'none', visibility: 'hidden' }}
                      ></iframe>
                    </noscript>
                  </div>
                )}{' '}
                <ToastContainer
                  limit={1}
                  autoClose={2000}
                  rtl={i18n.language === 'ar' ? true : false}
                />
              </AppProvider>
            </SeoTagProvider>
          </CityProvider>
          {/* </MoEngageProvider> */}
        </StoreProvider>
      </TranslationProvider>
    </GlobalDataProvider>
  );
}
MyApp.getInitialProps = async ({ ctx }: { ctx: NextPageContext }) => {
  const { locale, asPath, pathname } = ctx;
  const host = process.env.NEXT_PUBLIC_APP_DOMAIN || 'www.gogomotor.com';
  const response: AllConfigurationResponse =
    await ConfigurationService.getSeoTag(locale);
  const translations: AllConfigurationResponse =
    await ConfigurationService.getTranslations(locale);
  const LabelConstants: any = translations;
  const seoList: any = response || [];
  // Default metadata
  let metaData = {
    title: LabelConstants.GO_GO_MOTOR_PAGE_META_TITLE_GLOBAL,
    description: LabelConstants.GO_GO_MOTOR_PAGE_META_DESC_GLOBAL,
    keywords: [],
  };
  // Fetch metadata based on the URL path or canonical URL
  const metatag = seoList.find((resp: any) => {
    // if (resp.attributes.CanonicalURL.includes('[[...filter-params]]')) {
    //   return pathname === resp.attributes.CanonicalURL ;
    // }
    if (asPath?.startsWith('/car-details')) {
      return resp.attributes.CanonicalURL === '/car-details';
    }
    if (resp.attributes.CanonicalURL === '/cars/[[...filter-params]]') {
      return pathname === '/used-cars/[[...filter-params]]';
    }
    return asPath === resp.attributes.CanonicalURL;
  });
  if (metatag) {
    metaData = {
      title: metatag.attributes.MetaTitle,
      description: metatag.attributes.MetaDescription,
      keywords: [],
    };
  }
  let hrefLangUrl = '';
  let canonicalUrl = '';
  if (locale === 'en') {
    canonicalUrl = `https://${host}/en${asPath}`;
    hrefLangUrl = `https://${host}${asPath}`;
  } else if (locale === 'ar') {
    hrefLangUrl = `https://${host}/en${asPath}`;
    canonicalUrl = `https://${host}${asPath}`;
  }
  return {
    pageProps: {},
    metaData,
    hrefLangUrl: hrefLangUrl.replace(/\/$/, ''),
    canonicalUrl: canonicalUrl.replace(/\/$/, ''),
    locale: locale || 'x-default',
  };
};

export default appWithTranslation(
  withApplicationInsights({
    instrumentationKey: process.env.NEXT_PUBLIC_INSTRUMENTATION_KEY,
    isEnabled: process.env.NEXT_PUBLIC_IS_LOCAL !== 'true',
  })(MyApp as any)
);
