import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { AuthService, VehicleService } from '../../../helpers/services';
import { NewFilterUtils, SessionUtils } from '../../../helpers/utilities';
import { useCityContext } from '../../../provider/CityProvider';
import { useGlobalDataContext } from '../../../provider/GlobalDataProvider';
import { useTranslationContext } from '../../../provider/TranslationProvider';
import {
  Locales,
  MultipleSortByFilter,
  SortByFilter,
  SortDirection,
  VehicleAgeType,
} from '../../../types/enums';
import { BodyType, FilterParams } from '../../../types/models';
import {
  ArrowDownIcon,
  BookmarkIconNew,
  CartNewIcon,
  CloseBlackIcon,
  LocationIcon2,
  SearchIcon,
  VehicleIcon,
} from '../../icons';
import LocaleSwitcher from '../LanguageSwitcher';
import GogoLoader from '../Loader/Loader';
import { Modal, ModalBody } from '../SideModal';
import IdentifierService from '../../../helpers/services/identifier.service';
import { SellMyCarPathname } from '../../../types/constants';
import { PushDataToGTM, PushSCEventsToGTM } from '../../../helpers/utilities/gtm';
import { EventLabels, GTMEvents, GTMSubEvents, PageNames, SellMyCarEvents } from '../../../types/gtm';

type propTypes = {
  menuItem: any[];
  configurationEmail?: string;
  auth: {
    isAuthenticated: boolean;
    showSignUpButton: boolean;
  };
  onSignInClickHandler: React.MouseEventHandler<HTMLButtonElement>;
  setAuth: any;
  setIsLoggedIn: any;
  setOpenModal: any;
};

const MobileHeader: FC<propTypes> = ({
  menuItem,
  configurationEmail,
  auth,
  setAuth,
  onSignInClickHandler,
  setIsLoggedIn,
  setOpenModal,
}): ReactElement => {
  const router = useRouter();
  const { LabelConstants } = useTranslationContext();
  const { i18n } = useTranslation();
  const [show, setShow] = useState(false);

  const { selectCity } = useCityContext();
  const { generalData } = useGlobalDataContext();
  const { t } = useTranslation();

  const [bodyTypes, setBodyTypes] = useState<Array<BodyType>>([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchTopBrands = async () => {
      const bodyTypesData = await VehicleService.fetchBodyTypes(router.locale);

      setBodyTypes(bodyTypesData.filter((x) => x.IsSearchable));
    };
    fetchTopBrands();
  }, [router.locale]);

  const {
    control,
    handleSubmit: handleSearchFormSubmit,
    reset: resertSearchForm,
  } = useForm<{
    searchKey: string;
  }>({
    defaultValues: { searchKey: '' },
  });

  useEffect(() => {
    /* DROP DOWN */
    /* END DROP DOWN */
    document.addEventListener('scroll', function () {
      const headerTop = document.querySelector(
        '.header-mobile'
      ) as HTMLInputElement;
      const searchBoxIcon = document.getElementById(
        'searchicon'
      ) as HTMLInputElement;
      const searchBox = document.getElementById(
        'searchbox1'
      ) as HTMLInputElement;
      const headerCarFilter = document.querySelector(
        '.header-car-filter'
      ) as HTMLInputElement;
      if (headerTop) {
        searchBoxIcon?.classList.add('flex');
        const scrollThreshold = headerTop.offsetHeight;
        if (window.scrollY > scrollThreshold) {
          resertSearchForm();
          // headerTop.classList.add('header-hide');
          searchBoxIcon?.classList.remove('hidden');

          searchBox?.classList.add('hidden');
          headerCarFilter && headerCarFilter.classList.add('active');
        } else {
          // headerTop.classList.remove('header-hide');
          searchBoxIcon?.classList.add('hidden');
          searchBox?.classList.remove('hidden');
          headerCarFilter && headerCarFilter.classList.remove('active');
        }
      }
    });
  }, [resertSearchForm]);

  function handleShowSearchClick() {
    document.documentElement.scrollTop = 0;
    resertSearchForm();
  }

  useEffect(() => {
    const handleStart = (url: string) =>
      url !== router.asPath && setIsLoading(true);
    const handleComplete = () => {
      setIsLoading(false);
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  });

  const searchCar: SubmitHandler<{ searchKey: string }> = async (data: {
    searchKey: string;
  }) => {
    const searchKey = data.searchKey.trim();

    if (!searchKey.length) {
      return;
    }

    let appliedFilter: FilterParams = {
      locale: router.locale,
      makes: [],
      models: [],
      page: 1,
      size: 15,
      sortBy: SortByFilter.ListedDate,
      sortDir: SortDirection.Asc,
      multipleSortBy: [MultipleSortByFilter.AskingPrice],
      specs: [],
      type: VehicleAgeType.All,
    };

    const findBodyType: any = bodyTypes?.find(
      (resp) =>
        resp?.BodyType?.toLowerCase() == searchKey?.toLowerCase() ||
        resp?.BodyTypeKey?.toLowerCase() == searchKey?.toLowerCase()
    );

    if (findBodyType?.BodyTypeKey) {
      appliedFilter.bodyTypes = [findBodyType?.BodyTypeKey?.toString()];
    } else {
      if (searchKey) appliedFilter.makes = [searchKey?.toString()];
    }

    const { queryString, badges } = await NewFilterUtils.getSearchParams({
      ...appliedFilter,
      locale: router.locale,
    });

    if (badges?.length && badges?.find((resp) => resp?.type === 'Make')) {
      router.push(`/all-listings${queryString}`);
    } else {
      if (!appliedFilter?.bodyTypes?.length)
        appliedFilter.searchKey = searchKey;
      appliedFilter.makes = [];
      const { queryString } = await NewFilterUtils.getSearchParams({
        ...appliedFilter,
        locale: router.locale,
      });

      router.push(`/all-listings${queryString}`);
    }
  };

  return (
    <>
      {isLoading && <GogoLoader className="absolute top-0" />}
      <section className="header-mobile bg-white lg:hidden">
        <div className="header-top">
          <div className="mx-auto container px-4 mobile-container">
            <div className="relative flex py-3 justify-between">
              <div className="relative flex items-center lg:hidden">
                <button
                  id="navbar-toggle"
                  type="button"
                  className="relative inline-flex items-center justify-cente text-gray"
                  aria-controls="mobile-menu"
                  aria-label="mobile menu toggle"
                  aria-expanded="false"
                  onClick={() => {
                    setShow(true);
                    PushDataToGTM(GTMEvents.DataLayerUpdate, {
                      pageName: PageNames.Mobile_Menu,
                      pageCategory: PageNames.Mobile_Menu,
                      eventCategory: GTMSubEvents.Click,
                      eventLabel: `${PageNames.Mobile_Menu}`,
                    })
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="10"
                    viewBox="0 0 19 10"
                    fill="none"
                  >
                    <path
                      d="M17.6719 2.10742H1.67188"
                      stroke="#212121"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.3385 8.44092H1.67188"
                      stroke="#212121"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>

              <div className="relative flex items-center mx-[12px]">
                <div className="flex flex-shrink-0 items-center min-w-[50px]">
                  {generalData?.Logo?.data?.attributes?.url && (
                    <Link href={'/'}>
                      <a   
                      onClick={() => {
                          PushDataToGTM(GTMEvents.DataLayerUpdate, {
                            pageName: PageNames.Mobile_Menu,
                            pageCategory: PageNames.Mobile_Menu,
                            eventCategory: GTMSubEvents.Click,
                            eventLabel: `${PageNames.Mobile_Menu} > ${EventLabels?.GOGO_MOTOR_LOGO.toUpperCase()}`,
                          });
                        }}>
                      <picture className="cursor-pointer">
                        <source
                          srcSet={`${process.env.NEXT_PUBLIC_Image_CDN_URL}/CMS/Common/gogo-motors.svg`}
                          type="image/svg"
                        />
                        <img
                          src={`${process.env.NEXT_PUBLIC_CMS_API_URL}${generalData?.Logo?.data?.attributes?.url}`}
                          alt={LabelConstants?.GOGO_MOTOR_LOGO}
                          className="!h-6 w-[50px]"
                        />
                      </picture>
                      </a>
                    </Link>
                  )}
                </div>
              </div>

              <div className="mt-1">
                <LocaleSwitcher  pageName={PageNames.Mobile_Menu}/>
                {/* {activeLocale === 'ar' ? (
                  <Link href={{ pathname, query }} as={asPath} locale={'en'}>
                    <span className="cursor-pointer lang-linear-gradient font-[13px] font-medium border border-[#F0F0F0] p-[5px] rounded">
                      En
                    </span>
                  </Link>
                ) : (
                  <Link href={{ pathname, query }} as={asPath} locale={'ar'}>
                    <span className="cursor-pointer lang-linear-gradient font-[13px] font-medium border border-[#F0F0F0] p-[5px] rounded">
                      عربى
                    </span>
                  </Link>
                )} */}
              </div>

              <div
                className={`lg:relative flex items-center ${
                  i18n.language === 'ar' ? 'mr-auto' : 'ml-auto'
                }`}
              >
                {router?.pathname === '/' && (
                  <div
                    id="searchicon"
                    className="hidden items-center mx-4 ms-auto"
                  >
                    <button
                      type="button"
                      className="relative flex-shrink-0 bg-white text-gray"
                      onClick={handleShowSearchClick}
                    >
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.4612 19.0001C15.8795 19.0001 19.4613 15.4184 19.4613 11C19.4613 6.58174 15.8795 3 11.4612 3C7.04293 3 3.46118 6.58174 3.46118 11C3.46118 15.4184 7.04293 19.0001 11.4612 19.0001Z"
                          stroke="#3A3A3C"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M21.4639 21.0002L17.1638 16.7002"
                          stroke="#3A3A3C"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                )}

                <div className="relative min-w-[75px]">
                  <button
                    id="cityid"
                    aria-label="city"
                    className="flex items-center rounded-md text-[#3E3E3E] font-semibold"
                    onClick={() => {
                      setOpenModal(true);
                      PushDataToGTM(GTMEvents.DataLayerUpdate, {
                        pageName: PageNames.Mobile_Menu,
                        pageCategory: PageNames.Mobile_Menu,
                        eventCategory: GTMSubEvents.Click,
                        eventLabel: `${PageNames.Mobile_Menu} > ${selectCity?.City.toUpperCase()}`,
                      });
                    }}
                  >
                    <LocationIcon2 className="w-[14px] h-[14px]" />
                    <span className="mx-2 text-[12px] min-w-[37px]">
                      {selectCity?.City}
                    </span>
                    <ArrowDownIcon
                      className="h-[12px] w-[12px] text-gray"
                      fill="#3A3A3C"
                    />
                  </button>
                </div>
              </div>
            </div>

            {router?.pathname === '/' && (
              <div id="searchbox1" className="relative mt-8 search-mobile">
                <form
                  onSubmit={handleSearchFormSubmit(searchCar)}
                  className="relative"
                  // action=""
                >
                  <div
                    className={`pointer-events-none absolute inset-y-0  flex items-center ${
                      i18n.language === 'ar' ? 'right-[20px]' : 'left-[20px]'
                    }`}
                  >
                    <SearchIcon fill={'#6B7588'} />
                  </div>
                  <Controller
                    name="searchKey"
                    control={control}
                    render={({ field }) => {
                      return (
                        <input
                          {...field}
                          value={field.value || ''}
                          onChange={(event) => {
                            let userInput = event.target.value || '';
                            userInput = userInput
                              .trimStart()
                              .replace(/\s+/g, ' ');
                            let result = '';
                            const pattern =
                              i18n.language === 'ar'
                                ? undefined
                                : /[^A-Za-z0-9-_ ]+/;
                            userInput
                              .split('')
                              .forEach(
                                (character: any) =>
                                  (result =
                                    result + character.replace(pattern, ''))
                              );
                            field.onChange(result);
                          }}
                          className={`!w-full !h-[48px] block rounded-full border-0 bg-white-100 py-[9px] pl-[46px] pr-[46px] text-gray-100 opacity-[100%]  text-[16px] focus:outline-none placeholder-gray-500 font-medium`}
                          placeholder={t(
                            LabelConstants.SEARCH_YOUR_FAVOURITE_CAR
                          )}
                          type="text"
                          enterKeyHint="search"
                          autoComplete="off"
                        />
                      );
                    }}
                  />
                </form>
              </div>
            )}
          </div>
        </div>

        {router?.pathname === '/' && (
          <div className="header-car-filter gogo-motor-scroll-move lg:hidden">
            <div className="header-car-filter-item">
              <div className="header-car-filter-item-img">
                <Link href={'/newcars/new'}>
                  <a className="flex items-center">
                    <picture className="cursor-pointer">
                      <img
                        src="/images/buy-new-car.svg"
                        alt={'buy-new-car'}
                        loading="lazy"
                        className="!h-[48px] w-[48px]"
                      />
                    </picture>
                  </a>
                </Link>
              </div>
              <Link href={'/newcars/new'}>
                <a>
                  <p className="text-[13px] text-[#212121] font-medium rtl:h-[22px]">
                    {LabelConstants?.BUY_NEW_CARS}
                  </p>
                </a>
              </Link>
            </div>

            <div className="header-car-filter-item">
              <div className="header-car-filter-item-img">
                <Link href={'/used-cars'} className="flex items-center">
                  <a className="flex items-center">
                    <picture className="cursor-pointer">
                      <img
                        src="/images/buy-used-car.svg"
                        alt={'buy-used-car'}
                        loading="lazy"
                        className="!h-[48px] w-[48px]"
                      />
                    </picture>
                  </a>
                </Link>
              </div>
              <Link href={'/used-cars'}>
                <a>
                  <p className="text-[13px] text-[#212121] font-medium rtl:h-[22px]">
                    {LabelConstants?.BUY_USED_CAR}
                  </p>
                </a>
              </Link>
            </div>

            <div className="header-car-filter-item">
              <div className="header-car-filter-item-img">
                <Link href={'/sell-my-car'}>
                  <a className="flex items-center">
                    <picture className="cursor-pointer">
                      <img
                        src="/images/sell-your-car.svg"
                        alt={'sell-your-car'}
                        loading="lazy"
                        className="!h-[48px] w-[48px]"
                      />
                    </picture>
                  </a>
                </Link>
              </div>
              <Link href={'/sell-my-car'}>
                <a>
                  <p className="text-[13px] text-[#212121] font-medium rtl:h-[22px]">
                    {LabelConstants?.SELL_YOUR_CAR}
                  </p>
                </a>
              </Link>
            </div>

            <div className="header-car-filter-item">
              <div className="header-car-filter-item-img">
                <Link href={'/campaigns'}>
                  <a className="flex items-center">
                    <picture className="cursor-pointer">
                      <img
                        src="/images/offers.svg"
                        alt={'offers'}
                        loading="lazy"
                        className="!h-[48px] w-[48px]"
                      />
                    </picture>
                  </a>
                </Link>
              </div>
              <Link href={'/campaigns'}>
                <a>
                  <p className="text-[13px] text-[#212121] font-medium rtl:h-[22px]">
                    {LabelConstants?.DEALS}
                  </p>
                </a>
              </Link>
            </div>

            {/* <div className="header-car-filter-item">
            <div className="header-car-filter-item-img">
              <picture>
                <img src="images/car02.svg" alt="" />
              </picture>
            </div>
            <p>Services</p>
          </div> */}
          </div>
        )}
      </section>

      {show && (
        <Modal
          backdrop="static"
          show={show}
          onClose={() => setShow(false)}
          containerClassName="!w-full"
          showClose={false}
        >
          <>
            <ModalBody>
              <div className="!h-full">
                <div
                  className={`${
                    auth?.isAuthenticated ? 'h-[375px]' : 'h-[275px]'
                  } bg-[#F1F5F9] relative`}
                >
                  <div className="px-[34px] absolute w-full bottom-[15px]">
                    <div
                      onClick={() => setShow(false)}
                      className="flex justify-end mb-[50px] cursor-pointer"
                    >
                      <CloseBlackIcon className="w-5 h-5" />
                    </div>
                    <div className="flex justify-between items-center mb-[30px]">
                      <div className="flex items-center">
                        <picture>
                          <img
                            className="w-[48px] h-[48px] border-[0.8px] border-[#C7C9D9] rounded-full"
                            src="/images/no-user.png"
                            alt=""
                          />
                        </picture>
                        <div className="mx-5">
                          {typeof window !== 'undefined' &&
                          SessionUtils?.getUserDetails()?.MobileNumber ? (
                            <p className="text-[#212121] font-semibold text-[20px]">
                              {SessionUtils?.getUserDetails()?.MobileNumber}
                            </p>
                          ) : (
                            <p className="text-[#212121] font-semibold text-[20px]">
                              {LabelConstants?.GUEST_USER}
                            </p>
                          )}

                          {auth?.isAuthenticated && (
                            <p
                              className="text-[#5B0964] font-normal text-[13px] mt-1  cursor-pointer"
                              onClick={() => {
                                PushDataToGTM(GTMEvents.DataLayerUpdate, {
                                  pageName: PageNames.Mobile_Menu,
                                  pageCategory: PageNames.Mobile_Menu,
                                  eventCategory: GTMSubEvents.Click,
                                  eventLabel: `${PageNames.Mobile_Menu} > ${LabelConstants?.VIEW_PROFILE.toUpperCase()}`,
                                });
                                setShow(false);
                                router.push('/profile');
                              }}
                            >
                              {LabelConstants?.VIEW_PROFILE}
                            </p>
                          )}
                        </div>
                      </div>
                      {!auth.isAuthenticated && auth.showSignUpButton && (
                        <button
                          type="button"
                          id="loginid"
                          aria-label="login"
                          className="w-[80px] bg-[#4C0055] py-[10px] px-[20px] rounded-[6px] text-[13px] text-[#FFFFFF] font-medium"
                          onClick={(e) => {
                            onSignInClickHandler(e);
                            PushDataToGTM(GTMEvents.DataLayerUpdate, {
                              pageName: PageNames.Mobile_Menu,
                              pageCategory: PageNames.Mobile_Menu,
                              eventCategory: GTMSubEvents.Click,
                              eventLabel: `${PageNames.Mobile_Menu} > ${LabelConstants?.LOGIN.toUpperCase()}`,
                            });
                          }}
                        >
                          {LabelConstants?.LOGIN}
                        </button>
                      )}
                    </div>

                    <div className="grid grid-cols-2  gap-4">
                      <div
                        onClick={() => {
                          PushDataToGTM(GTMEvents.DataLayerUpdate, {
                            pageName: PageNames.Mobile_Menu,
                            pageCategory: PageNames.Mobile_Menu,
                            eventCategory: GTMSubEvents.Click,
                            eventLabel: `${PageNames.Mobile_Menu} > ${LabelConstants?.MY_CONCIERGE.toUpperCase()}`,
                          });
                          setShow(false);
                          router.push('/wishlist');
                        }}
                        className="grow  cursor-pointer bg-white rounded-[8px] md:rounded-none p-[15px] !shadow-[0_0.25rem_1.75rem_rgba(30,34,40,0.07)]"
                      >
                        <div>
                          <BookmarkIconNew stroke="#212121" fillOpacity={'0'} />
                          <h4 className="text-[13px] font-medium text-[#212121] mt-[12px]">
                            {LabelConstants?.MY_CONCIERGE}
                          </h4>
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          PushDataToGTM(GTMEvents.DataLayerUpdate, {
                            pageName: PageNames.Mobile_Menu,
                            pageCategory: PageNames.Mobile_Menu,
                            eventCategory: GTMSubEvents.Click,
                            eventLabel: `${PageNames.Mobile_Menu} > ${LabelConstants?.MY_CART.toUpperCase()}`,
                          });
                          setShow(false);
                          router.push('/cart');
                        }}
                        className="grow cursor-pointer bg-white rounded-[8px] md:rounded-none p-[15px] !shadow-[0_0.25rem_1.75rem_rgba(30,34,40,0.07)]"
                      >
                        <div>
                          <CartNewIcon />
                          <h4 className="text-[13px] font-medium text-[#212121] mt-[12px]">
                            {LabelConstants?.MY_CART}
                          </h4>
                        </div>
                      </div>
                      {auth.isAuthenticated && (
                        <div
                          onClick={() => {
                            PushDataToGTM(GTMEvents.DataLayerUpdate, {
                              pageName: PageNames.Mobile_Menu,
                              pageCategory: PageNames.Mobile_Menu,
                              eventCategory: GTMSubEvents.Click,
                              eventLabel: `${PageNames.Mobile_Menu} > ${LabelConstants?.MY_VEHICLES.toUpperCase()}`,
                            });
                            setShow(false);
                            router.push('/dashboard');
                          }}
                          className="grow cursor-pointer bg-white rounded-[8px] md:rounded-none p-[15px] !shadow-[0_0.25rem_1.75rem_rgba(30,34,40,0.07)]"
                        >
                          <div>
                            <VehicleIcon className="h-8 w-8" />
                            <h4 className="text-[13px] font-medium text-[#212121] mt-[12px]">
                              {LabelConstants?.MY_VEHICLES}
                            </h4>
                          </div>
                        </div>
                      )}
                      {/* {auth.isAuthenticated && (
                        <div
                          onClick={() => {
                            setShow(false);
                            router.push('/my-orders');
                          }}
                          className="grow  cursor-pointer bg-white rounded-[8px] md:rounded-none p-[15px] !shadow-[0_0.25rem_1.75rem_rgba(30,34,40,0.07)]"
                        >
                          <div>
                            <MyordersNewIcon />
                            <h4 className="text-[13px] font-medium text-[#212121] mt-[12px]">
                              {t(LabelConstants.MY_ORDERS)}
                            </h4>
                          </div>
                        </div>
                      )} */}
                    </div>
                  </div>
                </div>
                <div className="py-[34px] px-[34px]">
                  {menuItem.map((item, index) => {
                    return (
                      !item?.attributes?.IsHide && (
                        <div
                          key={index}
                          onClick={async () => {
                            setShow(false);
                            const clarityId =
                              await IdentifierService.getClarityUserId();
                            if (
                              item?.attributes?.URL &&
                              item?.attributes?.URL.includes(SellMyCarPathname)
                            ) {
                              PushSCEventsToGTM(SellMyCarEvents.CTA_CLICKED, {
                                clarity_id: clarityId,
                                button_location: 'Mobile Menu',
                              });
                            }
                            PushDataToGTM(GTMEvents.DataLayerUpdate, {
                              pageName: PageNames.Mobile_Menu,
                              pageCategory: PageNames.Mobile_Menu,
                              eventCategory: GTMSubEvents.Click,
                              eventLabel: `${PageNames.Mobile_Menu} > ${item?.attributes?.Menu.toUpperCase()}`,
                            });
                            router.push(item?.attributes?.URL);
                          }}
                          className="font-medium text-[#212121] text-[20px] mb-[15px]"
                        >
                          {item?.attributes?.Menu}
                        </div>
                      )
                    );
                  })}

                  <hr className="!bg-[#F0F0F0] my-[24px]" />

                  <a href={`mailto:${configurationEmail}`}
                   onClick={() => {
                    PushDataToGTM(GTMEvents.DataLayerUpdate, {
                      pageName: PageNames.Mobile_Menu,
                      pageCategory: PageNames.Mobile_Menu,
                      eventCategory: GTMSubEvents.Click,
                      eventLabel: `${PageNames.Mobile_Menu} > ${LabelConstants?.CAREERS.toUpperCase()}`,
                    });
                  }}>
                    <div className="font-medium text-[#212121] text-[20px] mb-[15px]">
                      {LabelConstants?.CAREERS}
                    </div>
                  </a>
                  <div
                    className="font-medium text-[#212121] text-[20px] mb-[15px]"
                    onClick={() => {
                      PushDataToGTM(GTMEvents.DataLayerUpdate, {
                        pageName: PageNames.Mobile_Menu,
                        pageCategory: PageNames.Mobile_Menu,
                        eventCategory: GTMSubEvents.Click,
                        eventLabel: `${PageNames.Mobile_Menu} > ${LabelConstants?.CONTACT_US.toUpperCase()}`,
                      });
                      setShow(false);
                      router.push('/info/contact-us');
                    }}
                  >
                    {LabelConstants?.CONTACT_US}
                  </div>
                  <div
                    className="font-medium text-[#212121] text-[20px] mb-[15px]"
                    onClick={() => {
                      PushDataToGTM(GTMEvents.DataLayerUpdate, {
                        pageName: PageNames.Mobile_Menu,
                        pageCategory: PageNames.Mobile_Menu,
                        eventCategory: GTMSubEvents.Click,
                        eventLabel: `${PageNames.Mobile_Menu} > ${LabelConstants?.ABOUT_US.toUpperCase()}`,
                      });
                      setShow(false);
                      router.push('/info/about-us');
                    }}
                  >
                    {LabelConstants?.ABOUT_US}
                  </div>
                  {auth.isAuthenticated && (
                    <div
                      className="font-medium text-[#212121] text-[20px] mb-[15px]"
                      onClick={async () => {
                        PushDataToGTM(GTMEvents.DataLayerUpdate, {
                          pageName: PageNames.Mobile_Menu,
                          pageCategory: PageNames.Mobile_Menu,
                          eventCategory: GTMSubEvents.Click,
                          eventLabel: `${PageNames.Mobile_Menu} > ${LabelConstants?.LOGOUT.toUpperCase()}`,
                        });
                        const logoutUrl = SessionUtils.getLogoutURL();
                        await AuthService.signOut(logoutUrl);
                        await AuthService.logout();
                        setAuth();
                        setIsLoggedIn();
                        router.push(router.locale === Locales.AR ? '/' : '/en');
                        setShow(false);
                        if (logoutUrl) {
                          window.location.href =
                            router.locale === Locales.AR ? '/' : '/en';
                        }
                      }}
                    >
                      {LabelConstants?.LOGOUT}
                    </div>
                  )}
                </div>
              </div>
            </ModalBody>
          </>
        </Modal>
      )}
    </>
  );
};

export default MobileHeader;
