import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  WarrantyConstants,
  WarrantyTypeConstants,
} from '../../../types/i18n.labels';
import { PackageType } from '../../../components/VASLandingSection/WarrantyFlow/WarrantyBuyPackage';
import { City } from '../../../types/models';

interface UserDetails {
  userId: string;
  sequenceNo: string;
  customerNationalId: string;
  dateOfBirth: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  email: string;
  poBoxNo: number;
  address: string;
  cityCode: City | null;
  finance: string;
  gender: number;
}

export type TWarrantyData = {
  vehicle_type: string;
  brand: {
    brandId: number;
    brandName: string;
    brandIcon: string;
    makeCode: number;
  };
  model: {
    modelId: number;
    modelName: string;
    modelYear: number;
    modelCode: number;
    modelYearCode: number;
  };
  variant: { variantId: number; variantName: string };
  kms_driven: number;
  cylinders: number;
  cubic_capacity: number;
  vehicle_specifications: { label: string; value: string };
  chassisNumber: string;
  vehicleValue: number;
  pre_warranty_check_date: string;
  vehicleListingId: string;
};

export type ElmData = {
  ownerId: string;
  plate1: string;
  plate2: string;
  plate3: string;
  plateNumber: string;
  registrationDate: string;
};

// Define a type for the slice state
export interface WarrantyState {
  isOpen: boolean;
  showAdditionalInfoModal: boolean;
  loader: boolean;
  type: WarrantyTypeConstants;
  currentStep: WarrantyConstants;
  isManuallSelection: boolean;
  data: TWarrantyData;
  inspection: {
    userlocation: {
      lat: number;
      long: number;
    };
    inspectionLocation: {
      id: number;
      lat: number;
      long: number;
      mapUrl: string;
      associateName: string;
      associatePhone: string;
      locationName: string;
      fullAddress: string;
      distance: number | string;
    };
    selectedSlot: {
      date: string;
      time: string;
    };
    allInspectionCenters: any[];
    lastVisitedStep: WarrantyConstants;
    isRescheduling: boolean;
  };
  package_data: PackageType | null;
  package_price: number | null;
  snapshotId: number;
  invoiceId: number;
  invoice: { orderNumber: string; totalAmount: string | number };
  user_details: UserDetails;
  promocode: string | null;
  paymentStatus: number | null;
  isLocationPermissionGranted: boolean;
  elmData: ElmData;
}

// Define the initial state using that type
export const warrantyInitialState: WarrantyState = {
  isOpen: false,
  showAdditionalInfoModal: false,
  loader: false,
  type: WarrantyTypeConstants.PreOwnedCar,
  currentStep: WarrantyConstants.VerifyYourCar,
  isManuallSelection: false,
  data: {
    vehicle_type: 'PRE OWNED',
    brand: {
      brandId: 0,
      brandName: '',
      brandIcon: '',
      makeCode: null as unknown as number,
    },
    model: {
      modelId: 0,
      modelName: '',
      modelYear: 1990,
      modelCode: null as unknown as number,
      modelYearCode: null as unknown as number,
    },
    variant: { variantId: 0, variantName: '' },
    kms_driven: 0,
    cylinders: 2,
    cubic_capacity: 0,
    vehicle_specifications: { label: 'GCC', value: 'GCC' },
    chassisNumber: '',
    vehicleValue: 0,
    pre_warranty_check_date: '',
    vehicleListingId: '',
  },
  user_details: {
    sequenceNo: '',
    userId: '',
    customerNationalId: '',
    dateOfBirth: '',
    firstName: '',
    lastName: '',
    mobileNumber: '',
    email: '',
    poBoxNo: 0,
    address: '',
    cityCode: null,
    finance: '',
    gender: 1,
  },
  inspection: {
    userlocation: {
      lat: 0,
      long: 0,
    },
    inspectionLocation: {
      id: 0,
      lat: 0,
      long: 0,
      mapUrl: '',
      associateName: '',
      associatePhone: '',
      locationName: '',
      fullAddress: '',
      distance: '',
    },
    selectedSlot: {
      date: '',
      time: '',
    },
    allInspectionCenters: [],
    lastVisitedStep: WarrantyConstants.SelectLocation,
    isRescheduling: false,
  },
  package_data: null,
  package_price: null,
  snapshotId: 0,
  invoice: {
    orderNumber: '',
    totalAmount: '',
  },
  invoiceId: 0,
  promocode: '',
  paymentStatus: null,
  isLocationPermissionGranted: false,
  elmData: {
    ownerId: '',
    plate1: '',
    plate2: '',
    plate3: '',
    plateNumber: '',
    registrationDate: '',
  },
};

export const warrantySlice = createSlice({
  name: 'warranty-flow',
  initialState: warrantyInitialState,
  reducers: {
    setOpenWarrantyFlow: (state, action) => {
      return { ...state, isOpen: action.payload };
    },
    updateWarrantyPaymnetStatus: (state, action: { payload: number }) => {
      return { ...state, paymentStatus: action.payload };
    },
    setIsManuallSelection: (state, action) => {
      return { ...state, isManuallSelection: action.payload };
    },
    resetWarrantyState: (state) => {
      return { ...warrantyInitialState };
    },
    setShowAdditionalInfoModal: (state, action) => {
      return { ...state, showAdditionalInfoModal: action.payload };
    },
    setWarrantyFlowType: (state, action) => {
      return { ...state, type: action.payload };
    },
    setLoader: (state, action) => {
      return { ...state, loader: action.payload };
    },
    updateWarrantyStep: (state, action) => {
      return { ...state, currentStep: action.payload };
    },
    updateWarrantyFlow: (state, action) => {
      return { ...state, data: { ...state.data, ...action.payload } };
    },
    updateWarrantySnapshotId: (state, action) => {
      return { ...state, snapshotId: action.payload };
    },
    updateWarrantyInvoiceId: (state, action) => {
      return { ...state, invoiceId: action.payload };
    },
    updateWarrantyInvoice: (state, action) => {
      return { ...state, invoice: { ...state.invoice, ...action.payload } };
    },
    rehydrateWarrantyState: (state, action) => {
      return { ...state, ...action.payload };
    },
    updateWarrantyPackageState: (state, action) => {
      return {
        ...state,
        package_data: { ...state.package_data, ...action.payload },
      };
    },
    updateInspectionDetails: (state, action) => {
      return {
        ...state,
        inspection: { ...state.inspection, ...action.payload },
      };
    },
    setInspectionRescheduling: (state, action) => {
      return {
        ...state,
        inspection: { ...state.inspection, isRescheduling: action.payload },
      };
    },
    updateUserDetails: (state, action) => {
      return {
        ...state,
        user_details: { ...state.user_details, ...action.payload },
      };
    },

    updateVehicleListingId: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          vehicleListingId: action.payload,
        },
      };
    },
    updateWarrantyPromocode: (state, action) => {
      return {
        ...state,
        promocode: action.payload,
      };
    },
    updateWarrantyPackagePrice: (state, action) => {
      return {
        ...state,
        package_price: action.payload,
      };
    },
    toggleLocationPermission: (state, action: { payload: boolean }) => {
      return {
        ...state,
        isLocationPermissionGranted: action.payload,
      };
    },
    updateElmData: (state, action: PayloadAction<Partial<ElmData>>) => {
      return {
        ...state,
        elmData: { ...state.elmData, ...action.payload },
      };
    },
  },
});

export const {
  setOpenWarrantyFlow,
  setWarrantyFlowType,
  setLoader,
  updateWarrantyFlow,
  updateWarrantyStep,
  updateWarrantySnapshotId,
  updateWarrantyInvoiceId,
  updateWarrantyInvoice,
  rehydrateWarrantyState,
  updateWarrantyPackageState,
  updateInspectionDetails,
  setShowAdditionalInfoModal,
  updateUserDetails,
  updateVehicleListingId,
  resetWarrantyState,
  setInspectionRescheduling,
  setIsManuallSelection,
  updateWarrantyPromocode,
  updateWarrantyPackagePrice,
  updateWarrantyPaymnetStatus,
  toggleLocationPermission,
  updateElmData,
} = warrantySlice.actions;

export default warrantySlice.reducer;
