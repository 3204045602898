export class CdnUtils {
  static getOciCdnSrc = (src: string, width: number): string => {
    if (src.startsWith('https://') || src.startsWith('http://')) {
      return src;
    }
    return `${process.env.NEXT_PUBLIC_OCI_IMAGE_CDN_BASE_URL}/cdn-cgi/image/width=${width},quality=75/assets${src}`;
  };

  static getAzureCdnSrc = (src: string) => {
    if (src.startsWith('https://') || src.startsWith('http://')) {
      return src;
    }
    return `${process.env.NEXT_PUBLIC_Image_CDN_URL}${src}`;
  };
}
