export const GreenCheckIcon = () => {
  return (
    <svg
      width="58"
      height="58"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="29" cy="29" r="24" fill="#4C0055" />
      <path
        d="M19.125 29.726L25.2223 35.823L38.8676 22.1777"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <circle cx="29" cy="29" r="24" fill="#038700" />
      <circle
        cx="29"
        cy="29"
        r="26.5"
        stroke="#038700"
        strokeOpacity="0.26"
        strokeWidth="5"
      />
      <path
        d="M19.125 29.725L25.2223 35.822L38.8676 22.1768"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
};
