import { Locales } from '../../types/enums';
import {
  ListingImageArtifact,
  ListingResponse,
  SearchRequestPayload,
  B2CPackages,
  AddToCartPayload,
  AddToCartResponse,
  MyOrders,
  PackageSubscriptionDetailsResponse,
  SearchAllResponse,
  ViewBreakUp,
  VehicleImageVerificationResponse,
  TVirtualVehicleListingResponse,
  CreateZohoTicketResponse,
  CreateZohoTicketPayload,
  SaveSelfListingFeedbackPayload,
  SaveSelfListingFeedbackResponse,
  fetchVehicleSpecificationsBySpecIdResponse,
  NodeApiResponse,
  SelfListingCurrentStepResp,
  SelfListingSavedDataResp,
  UpsertVehicleDetailsPayload,
  UpsertVehicleDetailsResponse,
  ConfirmDetailsV2Payload,
  GetVehicleListingImagesV2Response,
} from '../../types/models';
import { axiosInstance } from '../api';
import { CommonUtils, SessionUtils } from '../utilities';
import { axiosNodeInstance } from '../api/interceptors';

export type TVirtualVehicleListing = {
  Make: string;
  Model: string;
  Spec: string;
  ModelYear: string;
  VIN: string;
  IsNew: boolean;
  OdometerReading: number;
  AskingPrice: number;
  VehicleMakeCode: number;
  VehicleModelCode: number;
  SpecCode: number;
  ModelYearCode: number;
};

export type TUpdateVirtualVehicleListing = {
  VehicleListingId: number;
  InspectionUrl: string;
  InspectionId: string;
};
import { SelfListingConstants } from '../../types/i18n.labels';

export default class ListingService {
  /**
   * This service is use to fetch all vehicles details based on filter parameters.
   * @param {SearchRequestPayload} payload - filter params
   * @returns {Promise<ListingResponse>} Returns search result.
   */
  static searchVehicle = async (
    payload: SearchRequestPayload
  ): Promise<ListingResponse> => {
    try {
      const response = await axiosInstance.post<ListingResponse>(
        `/api/listing/search`,
        payload
      );
      return response.data;
    } catch (error) {
      console.error(`Error in VehicleService:fetchListingImages`, error);
      throw error;
    }
  };

  /**
   * This service is use to fetch all product catalogue and vehicles listings based on filter parameters.
   * @param {SearchRequestPayload} payload - filter params
   * @returns {Promise<SearchAllResponse>} Returns search result.
   */
  static searchAllVehicle = async (
    payload: SearchRequestPayload
  ): Promise<SearchAllResponse> => {
    const response = await axiosInstance.post<SearchAllResponse>(
      `/api/listing/searchall`,
      payload
    );
    return response.data;
  };

  /**
   * This service is use to fetch listing images.
   * @param {number}  [vehicleListingId] - vehicleListingId.
   * @param {string} [currentLocale="en"] - i18n locale. `@default - "en"`
   * @returns {Promise<Array<ListingImageArtifact>>} Returns list of make
   */
  static fetchListingImages = async (
    vehicleListingId: number | null,
    currentLocale: string = Locales.EN,
    bodyTypeCode: number | null
  ): Promise<Array<ListingImageArtifact>> => {
    try {
      const response = await axiosInstance.get<Array<ListingImageArtifact>>(
        `/api/vehicle/listingimages?languageId=${CommonUtils.getLanguageId(
          currentLocale
        )}&vehicleListingId=${vehicleListingId}`,
        {
          headers: { credentials: true },
          params: {
            bodyTypeCode: bodyTypeCode !== 0 ? bodyTypeCode : null,
          },
        }
      );
      return await response.data;
    } catch (error) {
      console.error(`Error in VehicleService:fetchListingImages`, error);
      throw error;
    }
  };

  /**
   * This service is used to verify is given image is of a vehicle.
   * @param {string}  [imageUrl] - imageUrl.
   * @returns {Promise<VehicleImageVerificationResponse>} Returns list of make
   */
  static verifyVehicleImage = async (
    imageUrl: string | null
  ): Promise<VehicleImageVerificationResponse> => {
    try {
      const response = await axiosNodeInstance.post(
        '/vision/analyze-image-for-vehicle',
        {
          url: imageUrl,
        }
      );
      // const response = await axios.post(
      //   'https://centralservices.gogomotor.com/backend-api/vision/analyze-image-for-vehicle',
      //   {
      //     url: imageUrl,
      //   },
      //   {
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //   }
      // );
      return await response.data;
    } catch (error) {
      console.error(`Error in VehicleService:fetchListingImages`, error);
      throw error;
    }
  };

  /**
   * This service is use to fetch listing Documents.
   * @param {number}  [vehicleListingId] - vehicleListingId.
   * @param {string} [currentLocale="en"] - i18n locale. `@default - "en"`
   * @returns {Promise<Array<ListingImageArtifact>>} Returns list of make
   */
  static fetchListingDocuments = async (
    vehicleListingId: number | null,
    currentLocale: string = Locales.EN
  ): Promise<Array<ListingImageArtifact>> => {
    try {
      const response = await axiosInstance.get<Array<ListingImageArtifact>>(
        `/api/Vehicle/listingdocuments?languageId=${CommonUtils.getLanguageId(
          currentLocale
        )}&vehicleListingId=${vehicleListingId}`,
        { headers: { credentials: true } }
      );
      return await response.data;
    } catch (error) {
      console.error(`Error in VehicleService:fetchListingDocuments`, error);
      throw error;
    }
  };

  /**
   * This service is use to fetch B2C Packages.
   * @param {string} [currentLocale="en"] - i18n locale. `@default - "en"`
   * @returns {Promise<Array<B2CPackages>>} Returns list of B2C packages
   */
  static fetchB2CPackages = async (
    currentLocale: string = Locales.EN
  ): Promise<Array<B2CPackages>> => {
    try {
      const response = await axiosInstance.get<Array<B2CPackages>>(
        `/api/b2cpackage/getpackageservice?languageId=${CommonUtils.getLanguageId(
          currentLocale
        )}`,
        { headers: { credentials: true } }
      );
      return await response.data;
    } catch (error) {
      console.error(`Error in VehicleService:fetchB2CPackages`, error);
      throw error;
    }
  };

  /**
   * This service is use to add packages to cart.
   * @param {AddToCartPayload} payload - add to cart params
   * @returns {Promise<AddToCartResponse>} Returns result.
   */
  static addToCart = async (
    payload: AddToCartPayload
  ): Promise<AddToCartResponse> => {
    const response = await axiosInstance.post<AddToCartResponse>(
      `api/ShoppingCart/add`,
      payload,
      { headers: { credentials: true } }
    );
    return response.data;
  };

  /**
   * This service is use to fetch My Orders.
   * @param {string} [currentLocale="en"] - i18n locale. `@default - "en"`
   * @returns {Promise<MyOrders>} Returns list of My Orders
   */
  static fetchMyOrders = async (
    currentLocale: string = Locales.EN
  ): Promise<MyOrders> => {
    try {
      const response = await axiosInstance.get<MyOrders>(
        `/api/PackageSubscription/GetMyOrders?languageId=${CommonUtils.getLanguageId(
          currentLocale
        )}`,
        { headers: { credentials: true } }
      );
      return await response.data;
    } catch (error) {
      console.error(`Error in Listing Service:fetchMyOrders`, error);
      throw error;
    }
  };

  /**
   * This service is use to get package subscription details.
   * @param {string} [currentLocale="en"] - i18n locale. `@default - "en"`
   * @returns {Promise<MyOrders>} Returns list of My Orders
   */
  static getPackageSubscription = async (
    currentLocale: string = Locales.EN,
    packageSubscriptionId: string | string[]
  ): Promise<PackageSubscriptionDetailsResponse> => {
    try {
      const response =
        await axiosInstance.get<PackageSubscriptionDetailsResponse>(
          `/api/PackageSubscription/${packageSubscriptionId}?languageId=${CommonUtils.getLanguageId(
            currentLocale
          )}`,
          { headers: { credentials: true } }
        );
      return await response.data;
    } catch (error) {
      console.error(`Error in Listing Service:getPackageSubscription`, error);
      throw error;
    }
  };

  /**
   * This service is use to get package subscription details.
   * @returns {Promise<MyOrders>} Returns list of My Orders
   */
  static getValidatePurchaseEligibility =
    async (): Promise<PackageSubscriptionDetailsResponse> => {
      try {
        const response =
          await axiosInstance.get<PackageSubscriptionDetailsResponse>(
            `api/Vehicle/validatepurchaseeligibility`,
            { headers: { credentials: true } }
          );
        return await response.data;
      } catch (error) {
        console.error(`Error in Listing Service:getPackageSubscription`, error);
        throw error;
      }
    };

  /**
   * This service is use to get package subscription details.
   * @returns {Promise<[index: string]>} Returns hash data.
   */
  static saveFinanceRequest = async (payload: {
    BuyerId: string;
    ShoppingCartId: number;
    VehicleListingId: number;
  }): Promise<[index: string]> => {
    try {
      const response = await axiosInstance.post<Promise<[index: string]>>(
        `api/Vehicle/savefinancerequest`,
        payload,
        { headers: { credentials: true } }
      );
      return await response.data;
    } catch (error) {
      console.error(`Error in Listing Service:getPackageSubscription`, error);
      throw error;
    }
  };

  /**
   * This service is use to check availability of vehicle.
   * @returns {Promise<MyOrders>} Returns list of My Orders
   */
  static checkAvailability = async (
    vehicleListingId: number
  ): Promise<boolean> => {
    try {
      const response = await axiosInstance.get<boolean>(
        `/api/Vehicle/checkavailability?vehicleListingId=${vehicleListingId}`,
        { headers: { credentials: true } }
      );
      return await response.data;
    } catch (error) {
      console.error(`Error in Listing Service:getPackageSubscription`, error);
      throw error;
    }
  };
  /**
   * This service is use to get view breakup.
   * @param {number}  [B2COrderId] - B2COrderId.
   * @param {string} [currentLocale="en"] - i18n locale. `@default - "en"`
   * @returns {Promise<Array<ViewBreakUp>>} Returns list of make
   */
  static getViewBreakUp = async (
    B2COrderId: string | string[],
    currentLocale: string = Locales.EN
  ): Promise<Array<ViewBreakUp>> => {
    try {
      const response = await axiosInstance.get<Array<ViewBreakUp>>(
        `api/ShoppingCart/vieworderbreckup?B2COrderId=${B2COrderId}&languageId=${CommonUtils.getLanguageId(
          currentLocale
        )}`,
        { headers: { credentials: true } }
      );
      return await response.data;
    } catch (error) {
      console.error(`Error in VehicleService:fetchListingDocuments`, error);
      throw error;
    }
  };
  /**
   * This service is used to get number of days required to define a Listing as new listing
   * @returns {Promise<number>} Returns number of days
   */
  static getNewlyListedDays = async (): Promise<number> => {
    try {
      const response = await axiosNodeInstance.get<any>(
        `/strapi/uc-newly-listed-days-count`
      );
      return (await (response &&
        response.data &&
        response.data.data &&
        response.data.data.attributes))
        ? Number(response.data.data.attributes.daysCount)
        : 0;
    } catch (error) {
      console.error(`Error in ListingService:getNewlyListedDays`, error);
      throw error;
    }
  };

  /**
   * This service is used to report a vehicle listing
   * @returns {Promise<CreateZohoTicketResponse>} Returns number of days
   */
  static reportVehicleListing = async (
    payload: CreateZohoTicketPayload
  ): Promise<CreateZohoTicketResponse> => {
    try {
      const response = await axiosNodeInstance.post<any>(
        `/common/zoho-create-ticket`,
        payload
      );
      return await response.data;
    } catch (error) {
      console.error(`Error in ListingService:reportVehicleListing`, error);
      throw error;
    }
  };

  /**
   * This service is used to submit feedback for a self listed vehicle
   * @returns {Promise<SaveSelfListingFeedbackResponse>}
   */
  static saveSelfListingFeedback = async (
    payload: SaveSelfListingFeedbackPayload
  ): Promise<SaveSelfListingFeedbackResponse> => {
    try {
      const response = await axiosNodeInstance.post<any>(
        `/listing/upsert-vehicle-listing-feedback`,
        payload
      );
      return await response.data;
    } catch (error) {
      console.error(`Error in ListingService:saveSelfListingFeedback`, error);
      throw error;
    }
  };

  static saveVirtualVehicleListing = async (
    payload: TVirtualVehicleListing
  ): Promise<TVirtualVehicleListingResponse> => {
    const response = await axiosInstance.post(
      `api/Document/savevirtualvehiclelisting`,
      payload
    );
    return response.data;
  };

  static updateVirtualVehicleListing = async (
    payload: TUpdateVirtualVehicleListing
  ): Promise<TVirtualVehicleListingResponse> => {
    const response = await axiosNodeInstance.post(
      `/listing/update-vehicle-listing-with-inspection`,
      payload,
      { headers: { credentials: true } }
    );
    return response.data;
  };

  static fetchVehicleSpecificationsBySpecId = async (
    specId: number,
    modelId: number,
    modelYearId: number,
    languageId: number = CommonUtils.getLanguageId(Locales.EN)
  ): Promise<fetchVehicleSpecificationsBySpecIdResponse> => {
    try {
      const response =
        await axiosNodeInstance.get<fetchVehicleSpecificationsBySpecIdResponse>(
          `/master-data/trim/${specId}/specifications?modelId=${modelId}&modelYearId=${modelYearId}&languageId=${languageId}`
        );
      return response.data;
    } catch (error) {
      console.error(
        `Error in ListingService:fetchVehicleSpecificationsBySpecId`,
        error
      );
      throw error;
    }
  };

  static saveCurrentListingStep = async (
    vehicleListingId: number,
    step: SelfListingConstants,
    gaId: string
  ): Promise<NodeApiResponse<boolean>> => {
    try {
      const finalHeaders: any = {
        'Content-Type': 'application/json',
      };
      if (SessionUtils.isValidSession()) {
        finalHeaders['Authorization'] =
          'Bearer ' + SessionUtils.getAccessToken();
      }
      const response = await axiosNodeInstance.post<NodeApiResponse<boolean>>(
        `/listing/upsert-current-step`,
        {
          vehicleListingId,
          gaId,
          currentStep: step as string,
        },
        {
          headers: finalHeaders,
        }
      );
      return response.data;
    } catch (error) {
      console.error(`Error in ListingService:saveCurrentListingStep`, error);
      throw error;
    }
  };

  static getLatestSLStep = async (
    gaId: string
  ): Promise<NodeApiResponse<SelfListingCurrentStepResp | null>> => {
    try {
      const finalHeaders: any = {
        'Content-Type': 'application/json',
      };
      if (SessionUtils.isValidSession()) {
        finalHeaders['Authorization'] =
          'Bearer ' + SessionUtils.getAccessToken();
      }
      const response = await axiosNodeInstance.get<
        NodeApiResponse<SelfListingCurrentStepResp | null>
      >(
        `/listing/latest-current-step${
          SessionUtils.isValidSession() ? '' : `?gaId=${gaId}`
        }`,
        {
          headers: finalHeaders,
        }
      );
      return response.data;
    } catch (error) {
      console.error(`Error in ListingService:getLatestSLStep`, error);
      throw error;
    }
  };

  static deleteSLStepData = async (
    vehicleListingId: number | null
  ): Promise<NodeApiResponse<boolean>> => {
    try {
      const finalHeaders: any = {
        'Content-Type': 'application/json',
      };
      if (SessionUtils.isValidSession()) {
        finalHeaders['Authorization'] =
          'Bearer ' + SessionUtils.getAccessToken();
      }
      const response = await axiosNodeInstance.delete<NodeApiResponse<boolean>>(
        `/listing/current-step/${vehicleListingId}`,
        {
          headers: finalHeaders,
        }
      );
      return response.data;
    } catch (error) {
      console.error(`Error in ListingService:deleteSLStepData`, error);
      throw error;
    }
  };

  static getSavedSLDataById = async (
    vehicleListingId: number,
    locale: string = Locales.EN
  ): Promise<NodeApiResponse<SelfListingSavedDataResp>> => {
    try {
      const response = await axiosNodeInstance.get<
        NodeApiResponse<SelfListingSavedDataResp>
      >(
        `/listing/sl-saved-data?vehicleListingId=${vehicleListingId}&languageId=${CommonUtils.getLanguageId(
          locale
        )}`
      );

      return response.data;
    } catch (error) {
      console.error(`Error in ListingService:getSavedSLData`, error);
      throw error;
    }
  };

  static upsertVehicleDetails = async (
    payload: UpsertVehicleDetailsPayload
  ): Promise<NodeApiResponse<UpsertVehicleDetailsResponse>> => {
    try {
      const response = await axiosNodeInstance.post<
        NodeApiResponse<UpsertVehicleDetailsResponse>
      >('/listing/vehicle-details', payload, {
        headers: {
          Authorization: `Bearer ${SessionUtils.getAccessToken()}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(`Error in ListingService:upsertVehicleDetails`, error);
      throw error;
    }
  };

  static confirmVehicleDetails = async (
    payload: ConfirmDetailsV2Payload
  ): Promise<NodeApiResponse<any>> => {
    try {
      const response = await axiosNodeInstance.put<NodeApiResponse<any>>(
        `/listing/confirm-details`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${SessionUtils.getAccessToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(`Error in ListingService:confirmVehicleDetails`, error);
      throw error;
    }
  };

  static getVehicleListingImagesV2 = async (
    vehicleListingId: number,
    locale: string = Locales.EN
  ): Promise<GetVehicleListingImagesV2Response> => {
    try {
      const response = await axiosNodeInstance.get<
        NodeApiResponse<GetVehicleListingImagesV2Response>
      >(
        `/listing/listing-images-artifacts?vehicleListingId=${vehicleListingId}&languageId=${CommonUtils.getLanguageId(
          locale
        )}`
      );

      return response.data.success
        ? response.data.data
        : {
            profile: null,
            spinUrl: null,
            internal: [],
            external: [],
            others: [],
          };
    } catch (error) {
      console.error(`Error in ListingService:getVehicleListingImagesV2`, error);
      throw error;
    }
  };
}
