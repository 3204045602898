import React, { useEffect } from 'react';

const AdBanner = (props: any) => {
  useEffect(() => {
    try {
      ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push(
        {}
      );
    } catch (error: any) {
      console.log(error.message);
    }
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: 'block' }}
      data-ad-client={`ca-${process.env.NEXT_PUBLIC_GOOGLE_PUBLISHER_ID}`}
      {...props}
    ></ins>
  );
};

export default AdBanner;
