import { createSlice } from '@reduxjs/toolkit';

type intialStateType = {
  canonicalUrl: string;
  isFirstLoad: boolean;
};

const initialState: intialStateType = {
  canonicalUrl: '',
  isFirstLoad: true,  
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setCanonicalUrl: (state, action) => {
      return { ...state, canonicalUrl: action.payload };
    },
    setFirstLoad: (state, action) => {
      return { ...state, isFirstLoad : action.payload  };  
    },
  },
});

export const { setCanonicalUrl, setFirstLoad } = globalSlice.actions;

export default globalSlice.reducer;
