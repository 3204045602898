export const CrownSilverIcon = () => {
  return (
    <svg
      width="36"
      height="31"
      viewBox="0 0 36 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5236_18630)">
        <path
          d="M17.9153 1.49958L17.916 1.50067L25.7555 13.9245L26.4992 15.1032L27.4323 14.0678L33.8319 6.96655L33.8327 6.96571C33.8671 6.92741 33.8972 6.91589 33.9118 6.91265C33.9251 6.90972 33.9403 6.90991 33.9609 6.9194C33.9835 6.92979 34.0208 6.95595 34.0546 7.01224L34.9593 6.46847L34.0546 7.01225C34.0889 7.06928 34.1124 7.14639 34.1122 7.23294V7.23464V25.1324C34.1122 26.3463 33.6765 27.4912 32.9296 28.3196C32.186 29.1444 31.2024 29.5838 30.203 29.5838H5.37888C4.37947 29.5838 3.39581 29.1444 2.65221 28.3196C1.90536 27.4912 1.46962 26.3463 1.46962 25.1324L1.46962 7.23464L1.46962 7.23294C1.46948 7.14638 1.49297 7.06927 1.52725 7.01225C1.56108 6.95595 1.59835 6.92979 1.62093 6.9194C1.64158 6.90991 1.65676 6.90972 1.67003 6.91265C1.68466 6.91589 1.71472 6.92741 1.74917 6.96571L1.74992 6.96655L8.14957 14.0678L9.08262 15.1032L9.82638 13.9245L17.6658 1.50067L17.6665 1.49957C17.6943 1.45549 17.724 1.4308 17.7455 1.41836L17.2187 0.507684L17.7455 1.41836C17.7662 1.40642 17.7813 1.40419 17.7909 1.40419C17.8005 1.40419 17.8157 1.40642 17.8363 1.41836L18.3631 0.507684L17.8363 1.41836C17.8578 1.4308 17.8876 1.45549 17.9153 1.49958Z"
          fill="url(#paint0_linear_5236_18630)"
          stroke="#EEEEEE"
          strokeWidth="2.11111"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_5236_18630"
          x1="13.1912"
          y1="8.12575"
          x2="24.6329"
          y2="29.2992"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D7D7D7" />
          <stop offset="0.495161" stopColor="white" />
          <stop offset="1" stopColor="#9C9C9C" />
        </linearGradient>
        <clipPath id="clip0_5236_18630">
          <rect
            width="35.4583"
            height="30.6667"
            fill="white"
            transform="translate(0.25 0.1875)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
