import { Locales } from '../../types/enums';
import {
  Brand,
  City,
  GetMasterDataResponse,
  Make,
  ModelV1,
  ModelYearData,
  NodeApiResponse,
  Spec,
  SpecV1,
  TrimByIdResponse,
} from '../../types/models';
import { axiosNodeInstance } from '../api/interceptors';
import { CommonUtils } from '../utilities';

export default class MasterdataService {
  /**
   * This service is used to fetch makes
   * @returns {Promise<GetMasterDataResponse<Make>>}
   */
  static getMakes = async (
    locale: string = Locales.EN
  ): Promise<GetMasterDataResponse<Make>> => {
    try {
      const response = await axiosNodeInstance.get<GetMasterDataResponse<Make>>(
        `/master-data/makes?languageId=${CommonUtils.getLanguageId(locale)}`
      );
      return response.data;
    } catch (error) {
      console.error(`Error in MasterdataService:getMakes`, error);
      throw error;
    }
  };

  /**
   * This service is used to fetch popular makes
   * @returns {Promise<GetMasterDataResponse<Brand>>}
   */
  static getPopularMakes = async (
    locale: string = Locales.EN
  ): Promise<GetMasterDataResponse<Brand>> => {
    try {
      const response = await axiosNodeInstance.get<
        GetMasterDataResponse<Brand>
      >(
        `/master-data/popular-makes?languageId=${CommonUtils.getLanguageId(
          locale
        )}`
      );
      return response.data;
    } catch (error) {
      console.error(`Error in MasterdataService:getPopularMakes`, error);
      throw error;
    }
  };

  /**
   * This service is used to fetch model years
   * @returns {Promise<GetMasterDataResponse<ModelYearData>>}
   */
  static getModelYears = async (): Promise<ModelYearData[]> => {
    try {
      const response = await axiosNodeInstance.get<
        GetMasterDataResponse<ModelYearData>
      >(`/master-data/model-years`);
      return response.data.data || [];
    } catch (error) {
      console.error(`Error in MasterdataService:getModelYears`, error);
      throw error;
    }
  };

  /**
   * This service is used to fetch models
   * @returns {Promise<GetMasterDataResponse<ModelV1>>}
   */
  static getModels = async (
    makeId: number,
    modelYearId: number,
    locale: string = Locales.EN
  ): Promise<GetMasterDataResponse<ModelV1>> => {
    try {
      const response = await axiosNodeInstance.get<
        GetMasterDataResponse<ModelV1>
      >(
        `/master-data/${makeId}/models?modelYearId=${modelYearId}&languageId=${CommonUtils.getLanguageId(
          locale
        )}`
      );
      return response.data;
    } catch (error) {
      console.error(`Error in MasterdataService:getModels`, error);
      throw error;
    }
  };

  /**
   * This service is used to fetch cities
   * @returns {Promise<GetMasterDataResponse<ModelV1>>}
   */
  static getCities = async (locale: string = Locales.EN): Promise<City[]> => {
    try {
      const response = await axiosNodeInstance.get<GetMasterDataResponse<City>>(
        `/master-data/cities?languageId=${CommonUtils.getLanguageId(locale)}`
      );
      return response.data.success ? response.data.data : [];
    } catch (error) {
      console.error(`Error in MasterdataService:getModels`, error);
      throw error;
    }
  };

  /**
   * This service is used to fetch variants by spec
   * @returns {Promise<Array<Spec>>}
   */
  static fetchVariantsBySpec = async (
    modelId: number,
    currentLocale: string = Locales.EN
  ): Promise<GetMasterDataResponse<SpecV1>> => {
    try {
      const response = await axiosNodeInstance.get<
        GetMasterDataResponse<SpecV1>
      >(
        `/master-data/models/${modelId}/trims?languageId=${CommonUtils.getLanguageId(
          currentLocale
        )}`
      );
      return response.data;
    } catch (error) {
      console.error(`Error in MasterdataService:fetchVariantsBySpec`, error);
      throw error;
    }
  };

  /**
   * This service is used to fetch trim by id
   * @returns {Promise<TrimByIdResponse>}
   */
  static getTrimByTrimId = async (
    trimId: number
  ): Promise<TrimByIdResponse | null> => {
    try {
      const response = await axiosNodeInstance.get<
        NodeApiResponse<TrimByIdResponse>
      >(`/master-data/trim/${trimId}`);
      return response.data.success ? response.data.data : null;
    } catch (error) {
      console.error(`Error in MasterdataService:fetchTrimById`, error);
      throw error;
    }
  };
}
