const GogoLoader = ({ className = '' }) => {
  return (
    <div className={`globalloader ${className}`}>
      <picture>
        <img
          loading="lazy"
          src="/images/loader.gif"
          height={150}
          width={150}
          alt={'loader'}
          decoding="async"
        />
      </picture>
    </div>
  );
};

export default GogoLoader;
